
import DatePicker from "@/components/utils/IDatePicker.vue";
import ValidationMixin from "@/mixins/validation";
import { SiteStation, SiteTypes } from "@/store/modules/site/site.types";
import { DefaultState } from "@/types/types";
import { Validator } from "@/types/validator";
import { Component, Mixins, Ref, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";

const siteX = namespace("Site");

@Component({
  components: { DatePicker },
})
export default class EditSite extends Mixins(ValidationMixin) {
  @siteX.State(SiteTypes.UPDATE_SITE_STATE)
  public updateStation!: DefaultState;

  @siteX.State(SiteTypes.SITE_DATA)
  public userStation!: SiteStation;

  @siteX.Mutation(SiteTypes.SET_UPDATE_SITE_DIALOG)
  public setUpdateStation!: (updateStation: boolean) => void;

  @siteX.Action(SiteTypes.UPDATE_SITE)
  public saveStation!: (farmer: SiteStation) => Promise<void>;

  public updateStationStep = 1;

  public isValid = false;

  public site: SiteStation = {
    id: -1,
    name: "",
    region: "",
    zone: "",
    description: "",
    active: false,
    createdAt: new Date(),
  };

  public regions = ["NORTHERN", "SOUTHERN", "CENTRAL", "EASTERN", "WESTERN"];

  @Ref("form") public formRef!: Validator;

  closeUpdateStationDialog(): void {
    this.setUpdateStation(false);
  }

  async saveStationToDb(): Promise<void> {
    await this.formRef.validate();

    if (this.isValid) {
      await this.saveStation(this.site);
    }
  }

  @Watch("userStation")
  onDataChanged(value?: SiteStation): void {
    this.site = Object.assign({}, value);
  }

  mounted(): void {
    this.site = Object.assign({}, this.userStation);
  }
}
